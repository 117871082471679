import Head from 'next/head';

function Home() {
  return (
    <>
      <Head>
        <title>Honey Mart</title>
        <meta name="description" content="Apparel + Gifts" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="container mx-auto h-screen flex flex-col items-center justify-center">
        <i className="fa-solid fa-clock text-9xl mb-5" />
        <span className="font-bold text-3xl">Coming Soon...</span>
      </div>
    </>
  );
}

export default Home;
